@import '../../global.scss';

.susanWrapper {
    padding: 30px 130px 80px;

    @include extralarge{
        // padding: 30px 100px 80px;
        // background-color: gray;
    }
    @include large{
        padding: 30px 0px 80px;
        // background-color: green;
    }
    @include tablet{
        // background-color: orange;
    }
    @include mobile{
        // background-color: yellow;
    }

    .wrapper {
        gap: 20px;
        display: flex;
        margin-right: auto;
        margin-left: auto;
        align-items: center;
        color: $blackText;
        justify-content: center;
        flex-direction: column;
        margin-top: 10px;
        background-color: $white;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;

        .top {
            flex: 1;
            width: 100%;
            display: flex;
            padding: 20px 50px;
            align-items: center;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            justify-content: space-between;
            background-color: $moneyGreenItem;

            @include tablet{
                gap: 30px;
                padding: 60px 60px;
                flex-direction: column;
            }

            .imageOne {
                flex: 1;
                width: 100%;
                height: 370px;
                height: 300px;
                border-radius: 50%;
                @include tablet{
                    height: 320px;
                    height: 280px;
                }
            }
            .imageTwo {
                flex: 1;
                width: 100%;
                height: 370px;
                height: 330px;
                border-radius: 50%;
                @include tablet{
                    height: 320px;
                    height: 280px;
                }
            }

            .text {
                flex: 1.8;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;

                h3 {
                    font-size: 30px;
                    color: $TextBlue;
                    font-family: MulishMedium;

                    @include tablet{
                        font-size: 27px;
                    }
                }

                span {
                    font-size: 18px;
                    color: $TextBlack;
                    letter-spacing: 0.5px;

                    @include tablet{
                        font-size: 16px;
                    }
                }
            }

            .link {
                color: inherit;
                color: $black;
                margin-top: 15px;

                .founderIcon {
                    font-size: 22px;
                    color: $hoverBlue;
                }
            }

        }

        .bottom {
            flex: 1;
            gap: 25px;
            width: 100%;
            display: flex;
            padding: 20px 50px 40px;
            flex-direction: column;
            background-color: $white;

            @include tablet{
                padding: 20px 20px 40px;
            }

            span{
                font-size: 15px;
                color: $TextBlack;
                letter-spacing: 0.5px;
                @include phone{
                    font-size: 17px;
                }
            }



        }

    }
}



.team{
    gap: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;

    .teamWrapper {
        gap: 20px;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 50px 250px;
        margin-bottom: 100px;
        justify-content: center;
        flex-direction: column;

        @include extralarge {
            // padding: 50px 150px;
        }

        @include large {
            // padding: 50px 50px;
        }

        @include tablet {
            // padding: 50px 20px;
        }

        @include mobile {
            width: 450px;
            // padding: 50px 0px;
        }

        .teamItem {
            gap: 30px;
            display: flex;
            padding: 40px;
            border-radius: 20px;
            flex-direction: column;
            background-color: $lightPink;

            @include mobile {
                width: 100%;
                padding: 20px;
                position: unset;
                margin-top: 0px;
                border-radius: 0px;
            }

            .top {
                gap: 30px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                @include tablet{
                    gap: 40px;
                }
                @include mobile{
                    gap: 20px;
                }

                img {
                    // width: 180px;
                    // height: 180px;
                    width: 220px;
                    height: 220px;
                    border-radius: 20px;

                    @include mobile {
                        width: 120px;
                        height: 120px;
                    }

                }

                .divider2 {
                    width: 2px;
                    height: 80px;
                    display: inline-block;
                    background-color: $gray;

                    @include tablet {
                        // display: none;
                    }

                    @include phone {
                        // display: none;
                    }
                }

                .text {
                    gap: 5px;
                    display: flex;
                    padding: 10px 10px 15px;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;

                    h3 {
                        font-weight: 300;
                        font-size: 28px;
                        color: $darkBanking;
                        letter-spacing: 0.5px;

                        @include large {
                            font-size: 25px;
                        }

                        @include tablet {
                            font-size: 22px;
                        }
                    }

                    span {
                        color: $black;
                        font-size: 12px;
                        font-weight: 300;
                        text-align: center;
                        letter-spacing: 0.8px;
                        font-family: MulishMedium;

                        @include large {
                            // font-size: 16.5px;
                        }

                        @include mobile {
                            // font-family: Futura-Medium;
                        }
                    }

                    .link {
                        color: inherit;
                        color: $black;
                        margin-top: 15px;

                        .founderIcon {
                            font-size: 22px;
                            color: $hoverBlue;
                        }
                    }

                }

            }

            .bottom {
                gap: 10px;
                display: flex;
                flex-direction: column;

                span {
                    color: $black;
                    font-size: 16.5px;
                    font-weight: 300;
                    line-height: 1.4;
                    letter-spacing: 0.5px;
                    font-family: Futura-Light;
                    @include phone{
                        font-size: 17.5px;
                    }
                }

            }
        }
    }
}