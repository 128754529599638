@import '../../global.scss';

.aboutWrapper {
    gap: 130px;
    display: flex;
    margin-right: auto;
    margin-left: auto;
    padding: 40px 20px;
    align-items: center;
    color: $blackText;
    justify-content: center;
    flex-direction: column;


    .link {
        color: inherit;
        text-decoration: none;
    }

    @include extralarge {
        // background-color: gray;
    }

    @include large {
        gap: 100px;
        padding: 40px 10px;
        // background-color: green;
    }

    @include tablet {
        gap: 80px;
        // background-color: orange;
    }

    @include mobile {
        // background-color: yellow;
    }

    .aboutSec2 {
        gap: 10px;
        display: flex;
        width: 100%;
        // margin-top: 20px;
        align-items: center;
        justify-content: center;


        @include large {
            gap: 30px;
            flex-direction: column;
        }

        .text {
            flex: 1;
            gap: 30px;
            display: flex;
            height: 100%;
            color: $blackText;
            padding: 0px 30px;
            margin-top: -30px;
            flex-direction: column;

            @include tablet {
                padding: 25px 0px;
                margin-top: -30px;
            }


            span {
                font-size: 16px;
                color: $bankingGreen;
                letter-spacing: 0.8px;
                font-family: MulishMedium;

                @include large {
                    text-align: center;
                }
            }

            h1 {
                color: $TextBlack;
                font-size: 32px;
                margin-right: 110px;
                line-height: 1.5;
                font-family: MulishMedium;

                @include extralarge {
                    margin-right: 50px;
                }

                @include large {
                    margin-right: 0px;
                    text-align: center;
                    line-height: 1.3;
                }

                @include tablet {
                    font-size: 25px;
                }

                @include phone {
                    font-size: 28px;
                }
            }

            p {
                font-size: 16px;
                line-height: 1.3;
                letter-spacing: 0.8px;
            }
        }

        .image {
            flex: 1;
            gap: 10px;
            width: 100%;
            display: flex;
            flex-direction: column;

            .item {
                flex: 1;
                gap: 10px;
                display: flex;

                img {
                    flex: 1;
                    width: 200px;
                    object-fit: cover;
                    border-radius: 10px;

                    @include mobile {
                        width: 170px;
                    }

                }

                .top {
                    flex: 1;
                    object-fit: cover;
                    border-radius: 10px;
                }
            }
        }

    }

    .aboutSec3 {
        width: 100%;
        padding: 10px 0px;
        display: flex;
        flex-direction: column;
        margin-bottom: 40px;
        border-radius: 10px;

        .top {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;

            span {
                font-size: 30px;
                color: $TextBlack;
                font-family: MulishMedium;
            }

            .aboutHr {
                width: 180px;
                height: 0.5px;
                margin-bottom: 50px;
                border: 1px solid $TextBlack
            }
        }

        .founders {
            gap: 30px;
            display: flex;
            margin-top: 20px;
            padding: 0px 140px;
            align-items: center;
            justify-content: center;

            @include extralarge{
                padding: 0px 100px;
            }
            @include large{
                padding: 0px 0px;
            }

            @include tablet{
                width: 100%;
                padding: 0px 50px;
                flex-direction: column;
            }

            @include phone{
                width: 100%;
                padding: 0px 0px;
                flex-direction: column;
            }

            .founderItem {
                flex: 1;
                border: none;
                align-items: center;
                min-height: 325px;

                @include tablet{
                    width: 100%;
                }

                img {
                    height: 100%;
                    width: 100%;
                    border-radius: 20px;
                }

                .wrapper {
                    gap: 10px;
                    display: flex;
                    margin-top: 10px;
                    align-items: center;
                    flex-direction: column;

                    .textWrapper {
                        display: flex;
                        align-items: center;
                        padding: 0px 10px;
                        flex-direction: column;

                        h3 {
                            font-size: 22px;
                            margin-bottom: 3px;
                            font-family: MulishMedium;
                        }
                        span {
                            font-size: 16px;
                            margin-bottom: 3px;
                            font-family: MulishMedium;
                        }
                        p{
                            font-size: 14px;
                            color: $bankingBlue;
                            font-family: MulishMedium;
                            text-decoration: underline;
                        }

                    }

                    .profiles{
                        gap: 12px;
                        display: flex;
                        padding: 5px 20px 10px;

                        .icon{
                            font-size: 18px;
                            color: gray;
                        }
                    }
                }


            }
        }


    }

}