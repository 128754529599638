@import "../../global.scss";

.navbar {
    top: 0;
    height: 70px;
    width: 100%;
    display: flex;
    z-index: 999;
    position: sticky;
    padding: 0px 60px;
    align-items: center;
    border-bottom: 1px solid  $lightGray2;
    justify-content: space-between;
    background-color: $white;

    @include extralarge{
        // background-color: gray;
    }
    @include large{
        border-bottom: 0.5px solid  $lightGray2;
        // background-color: lightgreen;
    }
    @include tablet{
        // background-color: orange;
    }
    @include mobile{
        // background-color: yellow;
    }

    .link {
        color: inherit;
        text-decoration: none;
    }
    .itemIcon{
        font-size: 16px;
    }

    @include large {
        padding: 10px 30px;
    }

    @include mobile {
        padding: 10px 10px;
    }

    @include phone {
        padding: 10px 10px;
    }


    .link {
        gap: 5px;
        display: flex;
        color: inherit;
        align-items: center;
        text-decoration: none;

        .icon {
            font-size: 20px;
        }
    }

    .wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left {
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            @include large{
                margin-left: 12px;
            }

            .logoWrapper{
                display: flex;
                align-items: center;
                justify-content: center;

                .logoImage {
                    width: 27px;
                    margin-top: 5px;
                    @include large{
                        width: 25px;
                    }
                }
                .logoText {
                    display: flex;
                    font-size: 26px;
                    color: $bankingBlue;
                    letter-spacing: -1px;
                    font-family: 'DM Serif Display', serif;

                    @include large{
                        font-size: 23px;
                    }
                }
            }

        }

        .right {
            gap: 20px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .navbar-links {
                height: 100%;
                display: flex;
                align-items: center;


                .navList {
                    gap: 15px;
                    height: 100%;
                    display: flex;
                    list-style: none;
                    align-items: center;

                    .navItem {
                        height: 100%;
                        display: flex;
                        cursor: pointer;
                        font-size: 17px;
                        color: $TextBlue;
                        align-items: center;

                        span{
                            font-size: 16.5px;
                            font-family: MulishMedium;
                        }

                        &:hover {
                            color: $bankingBlue;
                        }
                    }

                    @include large {
                        display: none;
                    }
                }

            }

            .buttons {
                gap: 15px;
                display: flex;
                list-style: none;

                @include large {
                    display: none;
                }

                .navBtn {
                    height: 40px;
                    width: 100px;
                    font-size: 15px;
                    font-weight: 300;
                    color: #003153;
                    cursor: pointer;
                    background: #FAFAFA;
                    // border: 1px solid $bankingGreen;
                    border: 1px solid $bankingBlue;
                }
                .navBtn2 {
                    font-size: 15px;
                    height: 40px;
                    width: 120px;
                    border: none;
                    color: #FFFFFF;
                    cursor: pointer;
                    // background-color: $bankingGreen;
                    background-color: $bankingBlue;
                    &:hover{
                        background-color: #0f60d6;
                    }
                }

            }

        }

    }


    .hamburger {
        display: none;
        width: 32px;
        height: 24px;
        margin-right: 10px;
        cursor: pointer;
        align-items: center;

        @include large {
            gap: 5px;
            display: flex;
            flex-direction: column;

            .icon {
                width: 100%;
                color: $bankingGreen;
                font-size: 115px;
                cursor: pointer;
            }

        }

    }

    .menu {
        top: 70px;
        margin: 0;
        width: 100vw;
        z-index: 999;
        display: none;
        position: fixed;
        list-style: none;
        color: $bankingGreen;
        flex-direction: column;
        transition: all 1s ease;
        height: calc(100vh - 70px);
        background-color: $white;
        // background-color: $pageBackground;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px;

        @include large {
            display: flex;
        }

        .menuItem {
            gap: 10px;
            width: 100%;
            display: flex;
            cursor: pointer;
            color: $bankingBlue;
            padding: 15px 30px;
            align-items: center;
            border: 0.2px solid $lightGray2;
            justify-content: space-between;

            &:hover {
                color: $bankingGreen;
            }

            &:focus {
                color: $bankingGreen;
            }

            span{
                font-size: 16.5px;
                letter-spacing: 0.5px;
                font-family: MulishMedium;
                @include phone{
                    font-size: 20px;
                }
            }

            .dropIcon {
                color:  $gray;
                font-size: 14px;
            }
            .resIcon{
                margin-right: 10px;
            }
        }

        .res-buttons{
            gap: 20px;
            display: flex;
            padding: 20px 30px;
            flex-direction: column;

            .navBtn {
                height: 40px;
                width: 180px;
                font-size: 15px;
                font-weight: 300;
                color: #003153;
                cursor: pointer;
                background: #FAFAFA;
                border: 1px solid #0075b2;
            }
            .navBtn2 {
                font-size: 15px;
                height: 40px;
                width: 180px;
                border: none;
                color: #FFFFFF;
                cursor: pointer;
                background-color: #0075b2;
                font-weight: 300;
            }
        }


    }
}











