@import '../../global.scss';

.mainFooter {
    gap: 5px;
    width: 100%;
    display: flex;
    color: $white;
    align-items: center;
    padding: 20px 80px 10px;
    flex-direction: column;
    justify-content: space-between;
    // background: $black;
    background-color: $bankingBlue;
    // background-color: #8ab4f8;

    @include large {
        padding: 10px 40px;
    }

    @include tablet {
        padding: 10px 30px;
    }

    @include mobile {
        padding: 10px 30px;
    }

    .a-link{
        text-decoration: none;
        color: inherit;
    }



    .link {
        text-decoration: none;
        color: inherit;
    }

    .footerTop {
        width: 100%;
        display: flex;
        padding: 30px 0px;
        justify-content: space-between;

        @include tablet {
            padding: 35px 0px;
        }

        @include mobile {}

        .item {
            gap: 15px;
            // width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;


            span {
                font-size: 15px;

                &:hover {
                    text-decoration: underline;
                }

                @include tablet{
                    font-size: 13.5px;
                }
            }

            @include tablet {
                gap: 8px;
            }

        }


    }

    .footerCenter{
        display: flex;
        align-items: center;
        padding: 0px 160px;
        margin-bottom: 10px;
        justify-content: center;

        @include extralarge{
            padding: 0px 100px;
        }
        @include extralarge{
            padding: 0px 80px;
        }
        @include extralarge{
            padding: 0px 0px;
        }

        p{
            font-size: 11px;
            color: #acfce3;
            font-weight: 100;
            text-align: center;
            line-height: 15.5px;
            letter-spacing: 0.5px;
            @include phone{
                font-size: 12px;
            }
        }
    }

    .hrWrapper {
        width: 100%;
        display: flex;
        padding: 10px 0px;

        @include tablet {
            padding: 0px 0px;
        }

        hr {
            width: 100%;
            border: 0.01px solid #c1c4e5;
            border: 0.2px solid $lighterGray;
        }
    }

    .footerBottom {
        gap: 10px;
        width: 100%;
        display: flex;
        margin-bottom: 20px;
        flex-direction: column;

        .top {
            gap: 5px;
            width: 100%;
            display: flex;
            align-items: flex-start;
            flex-direction: column;

            h3{
                font-size: 14.5px;
            }
            p {
                font-size: 13.5px;
                color: $white;
                letter-spacing: 0.5px;
                text-decoration: none;
            }

            @include large{
                margin-top: 10px;
                align-items: center;
            }

        }

        .bottom {
            gap: 5px;
            width: 100%;
            display: flex;
            color: $white;
            padding: 10px 0px;
            align-items: center;
            justify-content: space-between;

            @include large {
                gap: 20px;
                flex-direction: column-reverse;
            }


            .left {
                gap: 5px;
                display: flex;
                font-size: 14px;
                align-items: center;

                .copyRight{
                    font-size: 12px;
                    margin-right: -4px;
                }

                span {
                    font-size: 12px;

                    @include mobile {
                        font-size: 12px;
                    }
                }
            }

            .right {
                gap: 5px;
                display: flex;
                align-items: center;

                span {
                    font-size: 13px;
                    margin-right: 10px;
                    font-weight: 100;
                }

                .rightItem {
                    padding: 8px;
                    display: flex;
                    border-radius: 50%;
                    align-items: center;
                    margin-right: 10px;
                    color: $moneyGreen;
                    border: 1px solid $white;
                    background-color: $white;

                    @include mobile {
                        padding: 6px;
                    }

                    &:hover {
                        color: $white;
                        border: 1px solid $white;
                        background-color: $moneyGreen;
                    }

                    .icon {
                        font-size: 14px;

                        @include mobile {
                            font-size: 13px;
                        }
                    }
                }
            }

        }

    }
}