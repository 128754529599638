@import '../../global.scss';

.process {
    gap: 150px;
    display: flex;
    margin-right: auto;
    margin-left: auto;
    padding: 80px 20px;
    align-items: center;
    color: $blackText;
    margin-bottom: 80px;
    justify-content: center;
    flex-direction: column;


    @include extralarge {
        // background-color: gray;
    }

    @include large {
        gap: 120px;
        // background-color: green;
    }

    @include tablet {
        gap: 100px;
        padding: 30px 0px;
        // background-color: orange;
    }

    @include mobile {
        // background-color: yellow;
    }


    //section 2
    .work {
        gap: 40px;
        // margin-top: 140px;
        display: flex;
        padding: 0px 70px;
        flex-direction: column;

        @include large {
            gap: 20px;
            padding: 0px 20px;
        }

        @include tablet {
            gap: 50px;
            padding: 20px 20px;
        }

        @include phone {
            padding: 30px 20px;
        }

        .workTop {
            gap: 40px;
            text-align: center;
            padding: 0px 120px;

            @include large {
                // padding: 0px 40px;
                padding: 0px 0px;
            }

            @include tablet {
                padding: 0px 0px;
            }

            h1 {
                font-size: 26px;
                line-height: 1.3;
                color: $TextBlack;
                letter-spacing: 0.8px;
                font-family: MulishMedium;

                @include large {
                    text-align: center;
                    font-size: 23px;
                }
                @include phone {
                    font-size: 32px;
                }
            }

            p {
                font-size: 17px;
                margin-top: 10px;
                line-height: 1.6;
                letter-spacing: 0.4px;
                color: $TextBlack;

                @include large {
                    margin-top: 20px;
                    padding: 0px 0px;
                    text-align: center;
                }

                @include tablet {
                    font-size: 16px;
                    text-align: center;
                }

                @include phone {
                    padding: 0px 0px;
                    font-size: 19px;
                }
            }
        }

        .workBottom {
            padding: 0px 60px;

            @include large {
                padding: 0px 0px;

            }

            .sec2Bottom {
                display: flex;
                align-items: center;
                border-radius: 20px;
                justify-content: center;
                box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 10px;
                    border: 0.2px solid $lighterGray;

                    @include mobile {
                        // height: 280px;
                    }
                }


            }
        }


    }

    .processes {
        gap: 60px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .processHeading {
            gap: 20px;
            display: flex;
            margin-right: 200px;
            // text-align: center;
            // padding: 0px 150px;
            flex-direction: column;

            @include extralarge {
                // padding: 0px 50px;
            }

            @include large {
                margin-right: 100px;
                // padding: 0px 30px;
            }

            @include tablet {
                margin-right: 60px;
                // padding: 0px 20px;
            }
            @include mobile {
                margin-right: 0px;
                // padding: 0px 20px;
            }


            h3 {
                font-size: 15px;
                line-height: 1.3;
                color: $bankingGreen;
                letter-spacing: 0.8px;
                font-family: MulishMedium;

                @include large {
                    // text-align: center;
                }

            }

            h1 {
                font-size: 26px;
                line-height: 1.3;
                color: $TextBlack;
                letter-spacing: 0.8px;
                font-family: MulishMedium;

                @include large {
                    // text-align: center;
                    font-size: 23px;
                }
                @include tablet{
                    display: none;
                }
            }

            h2 {
                display: none;
                @include tablet{
                    display: flex;
                    font-size: 23px;
                    color: $TextBlack;
                    letter-spacing: 0.8px;
                    font-family: MulishMedium;
                }
                @include phone {
                    font-size: 30px;
                }
            }

            span {
                font-size: 17px;
                line-height: 1.6;
                letter-spacing: 0.4px;
                color: $TextBlack;

                @include phone{
                    font-size: 18px;
                }
            }

        }

        .processWrapper1 {
            gap: 30px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);

            @include large {
                gap: 20px;
                grid-template-columns: repeat(2, 1fr);
            }

            @include tablet {
                gap: 20px;
                grid-template-columns: repeat(1, 1fr);
            }
            @include phone {
                padding: 0px 10px;
            }

            .processItem {
                gap: 10px;
                flex: 1;
                display: flex;
                padding: 30px 20px;
                border-radius: 20px;
                flex-direction: column;
                align-items: flex-start;
                background-color: $white;
                justify-content: flex-start;
                box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;

                .processIcon {
                    font-size: 30px;
                    color: $hoverBlue;
                }

                p {
                    font-size: 12px;
                    color: gray;
                    letter-spacing: 0.5px;
                    font-family: MulishMedium;
                }

                h1 {
                    font-size: 18px;
                    color: $TextBlue;
                    font-family: MulishMedium;
                }

                span {
                    color: #666A6D;
                    font-size: 16px;
                    line-height: 1.4;
                    letter-spacing: 0.8px;

                    @include phone {
                        font-size: 18.5px;
                    }
                }
            }
        }
    }

}