@import '../../../global.scss';

.dropdown-menu {
    top: 92px;
    width: 280px;
    float:inline-start;
    color: $black;
    list-style: none;
    text-align: start;
    position: absolute;
    background-color: $white;
    border-top: 3px solid $bankingGreen;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
}

.dropdown-menu li {
    font-size: 18px;
    color: $bankingBlue;
    cursor: pointer;
    font-weight: 400;
    letter-spacing: 0.5px;
    // font-family: 'Roboto', sans-serif;

    span{
        font-family: 'Roboto', sans-serif;
    }

}

.dropdown-menu li:hover {
    color: $bankingGreen;
}

hr {
    width: 100%;
    display: flex;
    border: 0.2px solid $lighterGray;
}


.dropdown-link {
    display: block;
    height: 100%;
    width: 100%;
    text-decoration: none;
    color: inherit;
    padding: 18px;
}

@media screen and (max-width: 960px) {
    .fa-caret-down {
        display: none;
    }
}