@import '../../global.scss';

.homeWrapper {
    gap: 160px;
    display: flex;
    margin-right: auto;
    margin-left: auto;
    padding: 50px 20px 40px;
    align-items: center;
    color: $blackText;
    justify-content: center;
    flex-direction: column;

    @include extralarge {
        // background-color: gray;
    }

    @include large {
        gap: 100px;
        padding: 40px 0px;
        // background-color: green;
    }

    @include tablet {
        gap: 80px;
        // background-color: orange;
    }


    //section 1
    .sec1 {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include large {
            gap: 60px;
            flex-direction: column;
        }

        .sec1Left {
            flex: 1;
            display: flex;
            flex-direction: column;
            margin-top: 30px;

            // position: relative;

            @include large {
                display: none;
            }

            h1 {
                color: $TextBlack;
                font-size: 45px;
                margin-right: 40px;
                align-items: center;
                justify-content: center;
                font-family: Mulish-ExtraBold;

                @include large {
                    margin-right: 0px;
                    margin-bottom: 10px;
                }

                @include tablet {
                    font-size: 40px;
                    margin-right: 0px;
                    margin-bottom: 10px;
                }

                @include mobile {
                    font-size: 35px;
                }
            }

            p {
                font-size: 17px;
                margin-top: 20px;
                margin-right: 70px;
                font-weight: 300;
                line-height: 1.6;
                letter-spacing: 0.4px;
                color: $TextBlack;

                @include large {
                    margin-right: 0px;
                    text-align: center;
                }

                @include phone{
                    font-size: 18px;
                }
            }


            .homeBtn1 {
                display: flex;
                height: 45px;
                width: 40%;
                border: none;
                cursor: pointer;
                font-weight: 300;
                margin-top: 20px;
                font-size: 15px;
                color: $white;
                align-items: center;
                justify-content: center;
                background-color: $bankingBlue;
                // background-color: $bankingGreen;

                @include mobile{
                    font-size: 28px;
                }
            }

            .homeBtn1:hover {
                background: $hoverBlue;
                border: 1px dotted $hoverBlue;
            }

        }

        .sec11Left {
            display: none;

            @include large {
                flex: 1;
                display: flex;
                flex-direction: column;
                margin-top: 30px;
                align-items: center;
                padding: 0px 100px;
            }
            @include tablet {
                align-items: center;
                padding: 0px 20px;
            }
            @include mobile {
                align-items: center;
                padding: 0px 20px;
                // padding: 0px 0px;
            }
            @include phone{
                padding: 0px 20px;
            }


            h1 {
                color: $TextBlack;
                font-size: 45px;
                margin-right: 40px;
                align-items: center;
                justify-content: center;
                font-family: Mulish-ExtraBold;

                @include large {
                    margin-right: 0px;
                }

                @include tablet {
                    font-size: 40px;
                    margin-right: 0px;
                }

                @include mobile {
                    font-size: 35px;
                }
            }

            p {
                font-size: 17px;
                margin-top: 20px;
                padding: 0px 80px;
                font-weight: 300;
                line-height: 1.6;
                letter-spacing: 0.4px;
                color: $TextBlack;

                @include large {
                    padding: 0px 20px;
                    text-align: center;
                }
                @include tablet{
                    font-size: 16px;
                    padding: 0px 0px;
                    text-align: center;
                }
                @include phone{
                    font-size: 20px;
                }
            }


            .homeBtn1 {
                display: flex;
                height: 45px;
                width: 40%;
                border: none;
                cursor: pointer;
                font-weight: 300;
                margin-top: 20px;
                font-size: 15px;
                color: $white;
                align-items: center;
                justify-content: center;
                background-color: $bankingBlue;

                @include mobile{
                    width: 60%;
                }
                @include phone{
                    font-size: 16px;
                }

            }

            .homeBtn1:hover {
                background: $hoverBlue;
                border: 1px dotted $hoverBlue;
            }

        }

        .sec1Right {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;

            @include large {
                padding: 0px 100px;
            }
            @include tablet {
                padding: 0px 20px;
            }
            @include mobile {
                padding: 0px 0px;
            }


            .imgWrapper {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 20px;
                margin-left: 0px;
                box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;

                img {
                    height: 100%;
                    width: 100%;
                    border-radius: 20px;

                }
            }
        }

        .sec1Right:nth-child(even) {
            margin-right: 0;
            margin-left: auto;
        }

    }

    //section 2
    .sec2 {
        gap: 40px;
        display: flex;
        padding: 0px 30px;
        flex-direction: column;

        @include large {
            gap: 20px;
            padding: 0px 20px;
        }

        @include tablet {
            gap: 50px;
            padding: 20px 0px;
        }
        @include phone {
            padding: 30px 0px;
        }

        .sec2Top {
            text-align: center;
            padding: 0px 120px;

            @include large {
                // padding: 0px 40px;
                padding: 0px 0px;
            }

            @include tablet {
                padding: 0px 0px;
            }

            .subHeading{
                font-size: 12px;
                color: $bankingGreen;
                letter-spacing: 0.6px;
                text-transform: uppercase;
                font-family: MulishLight;
            }

            h1 {
                margin-top: 5px;
                margin-bottom: 10px;
                text-align: center;
                color: $TextBlack;
                font-size: 37px;
                font-family: MulishMedium;

                @include tablet {
                    font-size: 30px;
                }

                @include mobile {
                    font-size: 25px;
                }
                @include phone{
                    font-size: 28px;
                }

            }

            p {
                font-size: 17px;
                margin-top: 10px;
                line-height: 1.6;
                letter-spacing: 0.4px;
                color: $TextBlack;
                @include large {
                    margin-top: 20px;
                    padding: 0px 0px;
                    text-align: center;
                }
                @include tablet{
                    font-size: 16px;
                    text-align: center;
                }
                @include phone{
                    padding: 0px 10px;
                    font-size: 20px;
                }
            }
        }

        .bottomWrapper {
            padding: 0px 60px;

            @include large {
                padding: 0px 0px;

            }

            .sec2Bottom {
                display: flex;
                align-items: center;
                border-radius: 20px;
                justify-content: center;
                box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 10px;
                    border: 0.2px solid $lighterGray;

                    @include mobile{
                        // height: 280px;
                    }
                }


            }
        }


    }

    //Section 3
    .sec3 {
        width: 100%;
        display: flex;

        @include extralarge {
            // height: 350px;
        }

        @include large {
            gap: 60px;
            padding: 0px 80px;
            flex-direction: column;
        }

        @include tablet {
            gap: 30px;
            padding: 0px 40px;
        }

        @include mobile {
            padding: 0px 0px;
        }

        .sec3Left {
            flex: 1;
            gap: 120px;
            display: flex;
            text-align: left;
            margin-right: 100px;
            border-radius: 15px;
            flex-direction: column;
            justify-content: center;

            @include extralarge {
                margin-right: 50px;
            }

            @include large {
                gap: 40px;
                text-align: center;
                margin-right: 0px;
            }

            h1 {
                color: $TextBlack;
                font-size: 37px;
                font-family: MulishMedium;

                @include tablet {
                    font-size: 28px;
                }

                @include mobile {
                    font-size: 25px;
                }
                @include phone{
                    font-size: 28px;
                }
            }

            .bottom{
                gap: 15px;
                display: flex;
                color: $bankingGreen;
                align-items: center;

                @include large {
                    justify-content: center;
                }

                .linkText{
                    gap: 10px;
                    display: flex;
                    align-items: center;

                    p {
                        font-size: 18px;
                        font-weight: 300;
                        font-family: MulishMedium;
                        @include mobile{
                            font-size: 16px;
                        }
                    }
                    .bottomIcon{
                        font-size: 16px;
                        font-weight: 300;
                    }
                }

            }

        }

        .image{
            flex: 1;
            gap: 10px;
            width: 100%;
            display: flex;
            flex-direction: column;

            .item{
                flex: 1;
                gap: 10px;
                display: flex;

                img{
                    flex: 1;
                    width: 200px;
                    object-fit: cover;
                    border-radius: 10px;
                    @include mobile{
                        width: 170px;
                    }

                }
                .top{
                    flex: 1;
                    object-fit: cover;
                    border-radius: 10px;
                }
            }
        }

    }

    /* Section Four */
    .points {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include large {
            gap: 60px;
            padding: 0px 80px;
            flex-direction: column;
        }

        @include tablet {
            // gap: 30px;
            padding: 0px 40px;
        }

        @include mobile {
            padding: 0px 0px;
        }

        .leftPoints {
            flex: 1;
            display: flex;
            color: $blackText;
            flex-direction: column;
            justify-content: center;

            @include tablet{
                padding: 0px 20px;
            }

            h1 {
                text-align: left;
                color: $TextBlack;
                font-size: 37px;
                font-family: MulishMedium;

                @include large {
                    display: none;
                }
                @include phone {
                    display: flex;
                    font-size: 32px;
                    align-items: center;
                    justify-content: center;
                }
            }
            .colorTextWrapper{
                gap: 10px;
                display: flex;
                align-Items: center;
                @include large{
                    display: none;
                }
                @include phone {
                    display: flex;
                    font-size: 32px;
                    align-items: center;
                    justify-content: center;
                }

                .colorText{
                    text-align: left;
                    color: $bankingGreen;
                    font-size: 37px;
                    font-family: MulishMedium;

                    // @include large {
                    //     display: none;
                    // }
                    @include phone {
                        font-size: 32px;
                    }
                }

            }

            .colorTextWrapper2{
                display: none;
                @include large{
                    display: flex;
                    gap: 10px;
                    display: flex;
                    align-Items: center;
                    justify-content: center;
                }
                @include phone {
                    display: none;
                }

                .colorText2{
                    color: $bankingGreen;
                    font-size: 32px;
                    font-family: MulishMedium;
                    @include tablet {
                        font-size: 25px;
                    }

                }

                h2 {

                    @include large {
                        display: flex;
                        font-size: 32px;
                        text-align: center;
                        align-items: center;
                        justify-content: center;
                        font-family: MulishMedium;
                    }

                    @include tablet {
                        font-size: 25px;
                    }

                    @include mobile {
                        // font-size: 25px;
                    }
                }

            }

            p {
                font-size: 17px;
                line-height: 26px;
                margin-right: 100px;
                font-weight: 300;
                margin-top: 50px;
                color: $TextBlack;

                @include extralarge {
                    // margin-right: 50px;
                }

                @include large {
                    text-align: center;
                    margin-right: 0px;
                    margin-top: 20px;
                }

                @include tablet {
                    font-size: 16.5px;
                }
                @include phone{
                    font-size: 20px;
                }
            }
        }

        .rightpoints {
            flex: 1;
            gap: 30px;
            width: 100%;
            display: flex;
            flex-direction: column;

            @include large{
                // padding: 0px 80px;
                // background-color: pink;
            }

            .top {
                gap: 10px;
                display: flex;
                @include mobile{
                    // width: 85%;
                }

                .pointscoins {
                    flex: 1;
                    width: 200px;
                    object-fit: cover;
                    border-radius: 10px;
                    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);
                    @include mobile{
                        width: 170px;
                    }
                }

                .depositpoints {
                    flex: 1;
                    width: 220px;
                    object-fit: cover;
                    border-radius: 10px;
                    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);
                    @include mobile{
                        width: 170px;
                    }
                }

            }

            .bottom {
                display: flex;
                align-items: center;
                justify-content: center;

                .loanpoints {
                    width: 80%;
                    height: 120px;
                    border-radius: 10px;
                    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);

                    @include large{
                        width: 60%;
                        height: 110px;
                    }
                    @include mobile{
                        width: 85%;
                        height: 100px;
                    }
                }
            }
        }

        // }
    }

    ///////NOTOFICATIONS
    .notify{ display: flex;
        align-items: center;
        justify-content: space-between;

        @include large {
            gap: 40px;
            padding: 0px 80px;
            flex-direction: column-reverse;
        }
        @include tablet {
            // gap: 30px;
            padding: 0px 40px;
        }

        @include mobile {
            padding: 0px 0px;
        }

    .notifyLeft {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        .imgWrapper {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 20px;
            border: 0.2px solid $lighterGray;

            img {
                height: 100%;
                width: 100%;
                border-radius: 20px;
            }
        }
    }

    .notifyRight{
        flex: 1;
        gap: 20px;
        width: 100%;
        display: flex;
        align-items: center;
        align-items: flex-end;
        flex-direction: column;
        justify-content: flex-end;



        @include large {
            align-items: center;
            justify-content: center;
        }

        .notifyTop{
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;

            @include large{
                flex-direction: row;
            }
            @include phone{
                flex-direction: column;
            }

            h3{
                display: none;
                @include large{
                    display: flex;
                    font-size: 30px;
                }
                @include phone{
                    display: none;
                }
            }

            h1 {
                color: $TextBlack;
                font-size: 37px;
                font-family: MulishMedium;

                @include tablet {
                    font-size: 28px;
                }

                @include mobile {
                    font-size: 25px;
                }
                @include phone{
                    font-size: 28px;
                }
            }
            h2 {
                color: $TextBlack;
                font-size: 37px;
                font-family: MulishMedium;

                @include large {
                    margin-left: 10px;
                }
                @include tablet {
                    font-size: 28px;
                }

                @include mobile {
                    font-size: 25px;
                }
                @include phone{
                    font-size: 28px;
                }
            }


        }

        p {
            font-size: 17px;
            margin-left: 80px;
            text-align: right;
            line-height: 1.6;
            letter-spacing: 0.4px;
            color: $TextBlack;

            @include large {
                margin-left: 0px;
                text-align: center;
            }

            @include phone{
                font-size: 18px;
            }
        }
    }

}

    /* Section five */
    .sec5Wrapper {
        gap: 40px;
        width: 100%;
        display: flex;
        border-radius: 15px;
        align-items: center;
        margin-bottom: 20px;
        margin-top: -30px;
        flex-direction: column;
        justify-content: center;

        @include large {
            padding: 0px 20px;
        }

        @include tablet {
            gap: 10px;
            margin-top: 20px;
            padding: 0px 80px;
        }

        @include mobile {
            padding: 0px 20px;
        }

        h1 {
            color: $TextBlack;
            font-size: 37px;
            font-family: MulishMedium;
            @include tablet {
                font-size: 28px;
            }
            @include mobile {
                font-size: 25px;
            }
            @include phone{
                font-size: 30px;
            }
        }

        .approach {
            gap:20px;
            width: 100%;
            display: grid;
            grid-template-columns: repeat(3, 1fr);

            @include large {
                grid-template-columns: repeat(2, 1fr);
            }

            @include tablet {
                margin-top: 40px;
                grid-template-columns: repeat(1, 1fr);
            }

            .listen {
                gap: 15px;
                display: flex;
                height: 340px;
                color: #FFFFFF;
                border-radius: 15px;
                padding: 0px 20px;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                background-color: $muiBackground;
                border: 0.2px solid $lighterGray;
                box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;

                @include large{
                    height: 300px;
                }

                .listenIcon{
                    font-size: 36px;
                    color: $muiDarkPurple;
                }

                h3 {
                    color: $TextBlack;
                    font-size: 25px;
                    letter-spacing: 0.5px;
                    font-family: MulishMedium;

                    @include tablet {
                        font-size: 22px;
                    }
                }

                p {
                    color: $TextBlack;
                    font-size: 16px;
                    font-weight: 300;
                    line-height: 1.4;
                    letter-spacing: 0.8px;
                    text-align: center;

                    @include phone{
                        font-size: 18.5px;
                    }
                }
            }

            .resolve {
                gap: 15px;
                display: flex;
                height: 340px;
                color: #FFFFFF;
                border-radius: 15px;
                padding: 0px 25px;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                background-color: $moneyGreenItem;
                border: 0.2px solid $lighterGray;
                box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;


                @include large{
                    height: 300px;
                }
                .resolveIcon{
                    font-size: 36px;
                    color: $bankingGreen;
                }

                h3 {
                    color: $TextBlack;
                    font-size: 25px;
                    letter-spacing: 0.5px;
                    font-family: MulishMedium;

                    @include tablet {
                        font-size: 22px;
                    }
                }

                p {
                    color: $TextBlack;
                    font-size: 16px;
                    font-weight: 300;
                    line-height: 1.4;
                    letter-spacing: 0.8px;
                    text-align: center;
                    @include phone{
                        font-size: 18.5px;
                    }
                }
            }


            .empower {
                gap: 15px;
                display: flex;
                height: 340px;
                color: #FFFFFF;
                border-radius: 15px;
                padding: 0px 25px;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                background-color: #fae5de;
                background-color: $lightPink;
                border: 0.2px solid $lighterGray;
                box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;

                @include large{
                    height: 300px;
                }

                .empowerIcon{
                    font-size: 36px;
                    color: #DE3782;
                }

                h3 {
                    color: $TextBlack;
                    font-size: 25px;
                    letter-spacing: 0.5px;
                    font-family: MulishMedium;

                    @include tablet {
                        font-size: 22px;
                    }
                }

                p {
                    color: $TextBlack;
                    font-size: 16px;
                    font-weight: 300;
                    line-height: 1.4;
                    letter-spacing: 0.8px;
                    text-align: center;
                    @include phone{
                        font-size: 18.5px;
                    }
                }
            }


        }
    }

    /* Section six */
    .sec6Wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 70px;
        justify-content: space-between;

        @include tablet {
            gap: 60px;
            padding: 0px 50px;
            flex-direction: column-reverse;
        }

        @include mobile {
            padding: 0px 0px;
        }


        .left {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;

            .imgWrapper {
                height: 100%;
                width: 100%;
                display: flex;
                margin-left: 50px;
                border-radius: 20px;
                align-items: center;
                justify-content: center;
                box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
                background-image: url("../../assets/phoneBack.png");
                background-size: cover;

                // background-color: $black;

                @include tablet{
                    margin-left: 0px;
                }

                img {
                    height: 100%;
                    width: 100%;
                    border-radius: 20px;
                }
            }
        }

        .right {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            h1 {
                color: $TextBlack;
                font-size: 38px;
                margin-bottom: 15px;
                font-family: MulishMedium;

                @include mobile {
                    font-size: 28px;
                }
                @include phone{
                    font-size: 30.5px;
                }
            }

            .action{
                display: flex;
                padding: 0px 30px;
                align-items: center;
                margin-bottom: 10px;
                flex-direction: column;
                justify-content: center;
            }

            span {
                font-size: 22px;
                line-height: 26px;
                font-weight: 300;
                text-align: center;
                font-family: MulishMedium;
            }

            .buttons {
                gap: 20px;
                display: flex;
                margin-top: 20px;

                @include tablet {
                    margin-top: 20px;
                }

                .appleBtn,
                .playBtn {
                    gap: 10px;
                    display: flex;
                    padding: 8px 10px;
                    align-items: center;
                    border-radius: 10px;
                    background-color: $darkBanking;
                    border: 0.5px solid $lighterGray;

                    .icon {
                        font-size: 28px;
                        color: $white;
                    }

                    .icon2 {
                        font-size: 25px;
                        color: $white;
                    }

                    .text {
                        display: flex;
                        flex-direction: column;

                        p {
                            font-size: 10px;
                            font-weight: 300;
                            text-align: left;
                            color: $white;
                        }

                        h3 {
                            color: $white;
                            font-size: 15px;
                        }
                    }
                }
            }
        }


    }

}