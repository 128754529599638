@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Familjen+Grotesk:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=Familjen+Grotesk:wght@400;500;600;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,500;0,600;0,700;0,800;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');


@font-face {
    font-family: Mulish-ExtraBold;
    font-style: 'normal';
    src: url(./fonts/Mulish-ExtraBold.ttf);
}
@font-face {
    font-family: Mulish-Bold;
    font-style: 'normal';
    src: url(./fonts/Mulish-Bold.ttf);
}
@font-face {
    font-family: MulishMedium;
    font-style: 'normal';
    src: url(./fonts/Mulish-Medium.ttf);
}
@font-face {
    font-family: MulishLight;
    font-style: 'normal';
    src: url(./fonts/MulishLight.ttf);
}
@font-face {
    font-family: MulishThin;
    font-style: 'normal';
    src: url(./fonts/MulishThin.ttf);
}


* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    font-family: MulishLight;
}

.app {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #F7F7F7;
}

.container {
    max-width: 1200px;
    padding: 0px 20px;
}

@media screen and (max-width: 1020px) {
    .container {
        width: 100%;
    }
}

/* LAPTOP */
@media screen and (max-width: 960px) {
    .container {
        width: 100%;
    }
}

/* TABLET */
@media screen and (max-width: 720px) {
    .container {
        width: 100%;
    }
}

/* MOBILE */
@media screen and (max-width: 567px) {
    .container {
        width: 100%;
    }
}

/* PHONE */
@media screen and (max-width: 480px) {
    .container {
        width: 100%;
        padding: 0px 10px;
    }
}



