@import '../../global.scss';

.questionWrapper {
    width: 100%;
    max-width: 1200px;
    display: flex;
    margin-right: auto;
    margin-left: auto;
    padding: 40px 170px;
    align-items: center;
    color: $blackText;
    margin-bottom: 80px;
    justify-content: center;
    flex-direction: column;

    @include extralarge{
        padding: 40px 100px;
        // background-color: gray;
    }
    @include large{
        padding: 40px 50px;
    }

    @include tablet{
        padding: 40px 30px;
    }

    @include mobile{
        padding: 40px 0px;
    }
    @include phone{
        padding: 30px 10px 10px;
    }



    .questionHeading {
        gap: 20px;
        display: flex;
        margin-top: 30px;
        margin-bottom: 30px;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h3 {
            font-size: 28px;
            color: $TextBlack;
            font-family: MulishMedium;

            @include tablet{
                font-size: 24px;
                text-align: center;
            }

        }

        p {
            font-size: 16px;
            font-weight: 300;
            @include tablet{
                font-size: 18px;
                text-align: center;
            }
        }
    }



}











