@import '../../global.scss';

.policy{
    gap: 50px;
    display: flex;
    margin-right: auto;
    margin-left: auto;
    padding: 50px 30px 40px;
    align-items: center;
    color: $blackText;
    justify-content: center;
    flex-direction: column;

    @include large {
        gap: 30px;
    }

    @include tablet {
        padding: 50px 0px 40px;
    }

    h1{
        color: $black;
    }

    .policyBody{
        gap: 10px;
        display: flex;
        flex-direction: column;

        .date{
            color: $moneyGreen;
            font-size: 15px;
            font-weight: 400;
        }
        .desc{
            font-size: 16px;
            text-align: justify;
            font-weight: 300;
            line-height: 1.5;
            @include tablet {
                font-size: 15px;
            }
        }
        h2{
            color: $black;
            font-size: 19px;
        }
        .subHeading{
            color: $black;
            font-size: 16px;
            font-weight: 400;
        }
        ul{
            gap: 10px;
            display: flex;
            padding: 0px 50px;
            flex-direction: column;
            margin-bottom: 20px;
            @include tablet {
                padding: 0px 30px;
            }

            li{
                font-size: 16px;
                text-align: justify;
                font-weight: 300;
                line-height: 1.5;
                @include tablet {
                    font-size: 15px;
                }
            }
        }
    }

    .policyContact{
        gap: 10px;
        display: flex;
        flex-direction: column;
        align-self: flex-start;

        h2{
            color: $black;
            font-size: 19px;
        }
        .desc{
            font-size: 16px;
            font-weight: 300;
            line-height: 1.5;
            @include tablet {
                font-size: 15px;
            }
        }
        .contact{
            gap: 3px;
            display: flex;

            p{
                font-size: 16px;
                font-weight: 300;
                @include tablet {
                    font-size: 15px;
                }
            }
            span{
                font-size: 16px;
                font-weight: 300;
                color: $moneyGreen;
                @include tablet {
                    font-size: 15px;
                }
            }
        }
    }


}