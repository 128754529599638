$white: #FFFFFF;
$red: #E22D64;
$lightPink: rgba(222, 55, 130, 0.1);
$offWhite: #FAF9F6;
$lightGray: #b3b2b2;
$lighterGray: #c7c6c6;
$green: #007500;
$gray: #A9A9A9;
$lightGray2: rgba(211,211,211, 0.4);

// $moneyGreen: #118C4F;
$moneyGreen:#009688;
$moneyGreenLite: rgba(17, 140, 79, 0.2);
$moneyGreenItem: rgba(222,252,234, 0.4);
// $bankingGreen: #009688;
$mainBackground: #F7F7F7;
$bankingGreen: #009d4b;
$bankingBlue:#007EC5;
$darkBanking:#132644;
$TextBlue: #003153;
$TextBlack: #132644;
$hoverBlue: #0f60d6;
$black: #0B4141;
$blackText: #122231;
$mainBlack: #363636;
$black2: #1e1e29;
$blackHeading: #282828;
$lightPurpleText: #dfe3ff;
$muiDarkPurple: #9c27b0;
$muiLight: rgba(212, 144, 224, 0.05);
$muiBackground: rgba(212, 144, 224, 0.2);
$pageBackground: #f0f2f5;

// $extrasmall: 480px;
// $small: 576px;
// $medium: 768px;
// $large: 980px;
// $extralarge: 1100px;

$extrasmall: 480px;
$small: 576px;
$medium: 768px;
$large: 980px;
$extralarge: 1100px;

@mixin phone {
      @media (max-width: #{$extrasmall}) {
            @content
      }
};

@mixin mobile {
      @media (max-width: #{$small}) {
            @content
      }
};

@mixin tablet {
      @media (max-width: #{$medium}) {
            @content
      }
};

@mixin large {
      @media (max-width: #{$large}) {
            @content
      }
};

@mixin extralarge {
      @media (max-width: #{$extralarge}) {
            @content
      }
};
